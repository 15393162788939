<template>
  <span v-if="props.row.sessions">
    <i v-if="percentData > 50" class="fa fa-exclamation danger danger-icon"></i>
    {{ momentnow(fullPeriod - totalOff) }}
    <small v-if="fullPeriod - totalOff"
      :class="[
        percentData < 20 && 'success',
        percentData >= 20 && percentData <= 50 && 'warning',
        percentData > 50 && 'danger'
      ]"
    >
      ({{ (100 - percentData).toFixed(1) }}%)
    </small>
  </span>

  <span v-else class="danger">
    {{ $t('sessions.noSessions') }}
  </span>
</template>

<script>
/**
 * компонент - ячейка sessions.
 * компонент локальный: используется только в SessionsTable.vue
 * @component
 */

export default {
  name: 'SessionsCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    },
    sessionsDatepickerValues: {
      type: Object,
      required: true
    }
  },
  computed: {
    // legacy
    fullPeriod() {
      const periodStart = parseInt(this.sessionsDatepickerValues.startDate, 10) / 1000;
      let periodStop = parseInt(this.sessionsDatepickerValues.endDate, 10) / 1000;
      if (periodStop * 1000 > Date.now()) {
        periodStop = Date.now() / 1000;
      }
      return periodStop - periodStart;
    },
    // legacy
    totalOff() {
      const cpesSessions = this.props.row.sessions;
      const periodStart = this.sessionsDatepickerValues.startDate / 1000;
      let periodStop = this.sessionsDatepickerValues.endDate / 1000;
      if (periodStop * 1000 > Date.now()) {
        periodStop = Date.now() / 1000;
      }
      const period = periodStop - periodStart;
      if (!this.props.row.sessions) {
        return period;
      }
      let totalOff = period;
      cpesSessions.forEach((session) => {
        if (session.start < periodStart && (session.stop > periodStop || session.stop === 0)) {
          totalOff = 0;
        } else if (session.start > periodStart && (session.stop > periodStop || session.stop === 0)) {
          const onPeriod = periodStop - session.start;
          totalOff -= onPeriod;
        } else if (session.start < periodStart && session.stop < periodStop) {
          const onPeriod = session.stop - periodStart;
          totalOff -= onPeriod;
        } else if (session.start > periodStart && session.stop < periodStop) {
          const onPeriod = session.stop - session.start;
          totalOff -= onPeriod;
        }
      });

      return totalOff;
    },
    // legacy
    percentData() {
      const percentData = (100 * this.totalOff) / this.fullPeriod;
      if (percentData < 0.1 && percentData !== 0) {
        return 0.1;
      }
      if (percentData < 0) {
        return 0;
      }
      if (percentData > 100) {
        return 100;
      }
      return percentData.toFixed(1);
    }
  },
  methods: {
    // legacy
    momentnow(value) {
      const day = this.$t('general.day');
      const min = this.$t('general.min');
      const h = this.$t('general.hour');
      const sec = this.$t('general.sec');

      return moment.duration(value, 'seconds').format(`D [${day}] h [${h}], mm [${min}], ss [${sec}]`);
    }
  }
};
</script>

<style lang="css" scoped>
.danger-icon {
  margin-right: 4px;
}
.success {
  color: var(--brand-success);
}

.danger {
  color: var(--brand-danger);
}

.warning {
  color: var(--brand-warning);
}
</style>
