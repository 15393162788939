<template>
  <i v-if="props.row.connection" class="fa fa-wifi fa-lg primary"></i>
</template>

<script>
/**
 * компонент - ячейка connection.
 * компонент локальный: используется только в SessionsTable.vue
 * @component
 */

export default {
  name: 'ConnectionCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
.primary {
  color: var(--brand-primary);
}
</style>
