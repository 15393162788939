<template>
  <span :class="[!props.row.model && 'muted']">
    {{ props.row.model ? props.formattedRow[props.column.field] : $t('misc.no_data') }}
  </span>
</template>

<script>
/**
 * компонент - ячейка model.
 * компонент локальный: используется только в SessionsTable.vue
 * @component
 */

export default {
  name: 'ModelCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
.muted {
  color: var(--gray-light);
}
</style>
