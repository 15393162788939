<template>
  <span :class="[!props.row.location && 'muted']">
    {{ props.row.location ? locationName(props.row.location) : '-' }}
  </span>
</template>

<script>
/**
 * компонент - ячейка location.
 * компонент локальный: используется только в SessionsTable.vue
 * @component
 */

import commonService from '@/services/commonService';

export default {
  name: 'LocationCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  methods: {
    locationName(id) {
      const showLocationFromId = commonService.showLocationFromId(id);
      return showLocationFromId;
    }
  }
};
</script>

<style lang="css" scoped>
</style>
