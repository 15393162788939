/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/**
 * конфигурация таблицы SessionsTable.vue
*/

/**
 * генератор колонок таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/column-options.html#label
*/

import {
  getSelectedRowValue
} from './utils';

function generateTableColumns({ i18nInstance, hiddenColumnRules, additionalRules }) {
  const $t = i18nInstance.t.bind(i18nInstance);
  const { areLocationsVisible } = additionalRules;

  return [
    {
      label: $t('general.model'),
      field: 'model',
      width: 'auto',
      type: 'text',
      tdClass: 'sessions-table-td-wrapper',
      hidden: hiddenColumnRules.model
    },
    {
      label: $t('general.name'),
      field: 'name',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.name
    },
    {
      label: $t('monitor.sessions'),
      field: 'sessions',
      width: 'auto',
      type: 'text',
      sortFn: (x, y, col, rowX, rowY) => {
        // x - row1 value for column
        // y - row2 value for column
        // col - column being sorted
        // rowX - row object for row1
        // rowY - row object for row2

        const rowX_offPeriod = rowX.nativeData.offPeriod;
        const rowY_offPeriod = rowY.nativeData.offPeriod;

        if (rowX_offPeriod < rowY_offPeriod) {
          return -1;
        }

        if (rowX_offPeriod > rowY_offPeriod) {
          return 1;
        }

        return 0;
      },
      hidden: hiddenColumnRules.sessions
    },
    {
      label: $t('general.location'),
      field: 'location',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.location,
      visibilityDropdownSettings: {
        hidden: !areLocationsVisible
      }
    },
    {
      label: '',
      field: 'connection',
      width: 'auto',
      type: 'text',
      sortable: false,
      hidden: hiddenColumnRules.connection,
      visibilityDropdownSettings: {
        hidden: true
      }
    }
  ];
}

/**
 * генератор строк таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/#rows
 * https://xaksis.github.io/vue-good-table/guide/advanced/grouped-table.html#customizing-header-row
*/
function generateTableRows({ sessionsData, selectedRowSessionsIds }) {
  return sessionsData
    // не отображаем в таблице те, у которых нет имени. Такая логика была до рефакторинга.
    .filter(session => !!session.name)
    .map(session => {
      const {
        id,
        model,
        name,
        offPeriod,
        sessions,
        connected,
        base_location
      } = session;

      return {
        // nativeData - хранит неформатированные данные. Нужны, чтобы удобно эмитить их по событию для дальнейших манипуляций.
        nativeData: session,
        model: model?.name,
        name,
        sessions,
        location: base_location,
        connection: connected
        // выбрана ли строка в чекбоксе исходя из стейта, который контролирует выбранные строки
        // vgtSelected: getSelectedRowValue(id, selectedRowSessionsIds)
      };
    });
}

export {
  generateTableColumns,
  generateTableRows
};
